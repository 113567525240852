import React from "react"
import Layout from "../../layout"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import Video from "../../components/Video"
import Introduction from "../../components/Introduction"
import Carousel from "../../components/Carousel"
import VideoDetail from "../../components/VideoDetail"
import FeatureLine from "../../components/FeatureLine"
// import SpecsTable from "../../components/SpecsTable"
import FormContact from "../../components/FormContact"
import ControlPanel from "../../components/ControlPanel/new"
import ControlPanelMobile from "../../components/ControlPanel/mobile"
import TemperatureCounter from "../../components/TemperatureCounter"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import CarouselMobile from "../../components/Carousel/mobile"
import FeatureSliderMobile from "../../components/FeatureSliderMobile"
import FeatureLineMobile from "../../components/FeatureLine/mobile"
import Configurador from "../../components/Configurador/"

// ASSETS
import TurboramVideo from "../../assets/videos/Turboram/forno_turboram.mp4"
import TurboramVideoPreview from "../../assets/videos/Turboram/forno_turboram_preview.mp4"
import TurboramDetailVideo from "../../assets/videos/Turboram/forno_turboram_detalhe.mp4"
import TurboramDetailVideoLavagem from "../../assets/videos/Turboram/turboram-lavagem.mp4"
import TurboramVideoLavagem from "../../assets/videos/Turboram/turboram-auto-lavagem.mp4"
import TurboramVideoLavagemPreview from "../../assets/videos/Turboram/turboram-auto-lavagem-preview.mp4"

import { useTranslation, I18nextContext } from "gatsby-plugin-react-i18next"

const IndexPage = ({ location }) => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language
  var config = true
  if(activeLanguage === 'br' || activeLanguage === 'mx' ){
    config = false
  }
  return (
    <Layout location={location} footer={t("footer", { returnObjects: true })} header={t("header", { returnObjects: true })}>
      <SEO
        pathname={location.pathname}
        title={t("meta", { returnObjects: true })["title"]}
        keywords={t("meta", { returnObjects: true })["keywords"]}
        description={t("meta", { returnObjects: true })["description"]}
        img={t("meta", { returnObjects: true })["image"]}
        imageHeight="628"
        imageWidth="1200"
        lang={activeLanguage}
        domain={location?.hostname}
      />
      <Video
        className="full-width full-height scrollable"
        srcp={TurboramVideo}
        src={TurboramVideoPreview}
        poster={"https://ramalhos.com/video_posters/Turboram/forno_turboram.jpg"}
        width="100%"
        id="video-turboram"
        imgMobile={t("video", { returnObjects: true })["bgMobile"]}
        fullString={t("videoHome", { returnObjects: true })["info"]}
        toClick="#introduction"
      />

      <Introduction
        className="scrollable"
        id="introduction"
        translation={t("introduction", { returnObjects: true })}
        lang={activeLanguage}
        link={"../../config-turboram"}
        configurador
      />

      {!breakpoints.tl && ( // Desktop
        <>
          <Carousel
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            id="carousel-turboram"
            carouselContent={t("carousel", { returnObjects: true })}
          />
          <VideoDetail
            src={TurboramDetailVideo}
            poster="https://ramalhos.com/video_posters/Turboram/forno_turboram_detalhe.jpg"
            id="video-detail-turboram"
            _title={t("video", { returnObjects: true })["title1"]}
            text1={t("video", { returnObjects: true })["text1_1"]}
            text2={t("video", { returnObjects: true })["text1_2"]}
            notCentered
          />
          <FeatureLine
            id="turboram-feature"
            _title={t("feature2", { returnObjects: true })["title"]}
            positionCenter
            textWidth={"400px"}
          />
          <VideoDetail
            src={TurboramDetailVideoLavagem}
            poster="https://ramalhos.com/video_posters/Turboram/turboram-lavagem.jpg"
            id="video-turboram-lavagem"
            _title={t("video", { returnObjects: true })["title3"]}
            text1={t("video", { returnObjects: true })["text3_1"]}
            hasOverlay
          />
          <Carousel
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            id="carousel-turboram"
            carouselContent={t("carousel2", { returnObjects: true })}
          />
          <Video
            className="full-width"
            srcp={TurboramVideoLavagem}
            src={TurboramVideoLavagemPreview}
            poster="https://ramalhos.com/video_posters/Turboram/forno_turboram.jpg"
            width="100%"
            id="video-turboram-painel"
            imgMobile={t("video", { returnObjects: true })["bgMobile"]}
            fullString={t("videoPainel", { returnObjects: true })["info"]}
            noAnimation
          />
          <FeatureLine
            id="turboram-feature-2"
            _title={t("feature", { returnObjects: true })["title1"]}
            text={t("feature", { returnObjects: true })["text1"]}
            image={t("feature", { returnObjects: true })["image1_1"]}
            positionCenter
          />
          <ControlPanel
            id="control-panel-turboram"
            carouselContent={t("panel", { returnObjects: true })["panels"]}
            _title={{
              title1: t("panel", { returnObjects: true })["title1"],
              title2: t("panel", { returnObjects: true })["title2"],
            }}
            defaultImage={t("panel", { returnObjects: true })["panels"]["default"]}
          />
          <TemperatureCounter title={t("feature", { returnObjects: true })["title2"]} info={t("temp", { returnObjects: true })} />
          <FeatureLine
            id="turboram-feature-4"
            _title={t("feature", { returnObjects: true })["title3"]}
            text={t("feature", { returnObjects: true })["text3"]}
            image={t("feature", { returnObjects: true })["image3_1"]}
            image2={t("feature", { returnObjects: true })["image3_2"]}
            twoImages
            marginLeft="550px"
          />
        </>
      )}

      {/* --- */}

      {/* MOBILE  */}

      {breakpoints.tl && (
        <>
          <CarouselMobile
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            id="carousel-turboram-mobile"
            carouselContent={t("carousel", { returnObjects: true })}
          />
          <FeatureSliderMobile
            id="first-line-mobile"
            image1={t("feature", { returnObjects: true })["image1_1M"]}
            image2={t("feature", { returnObjects: true })["image1_2M"]}
            image3={t("feature", { returnObjects: true })["image2_1M"]}
          />
          <FeatureLineMobile
            id="second-line-mobile"
            _title={t("video", { returnObjects: true })["title1"]}
            text1={t("feature", { returnObjects: true })["text2"]}
            image={t("feature", { returnObjects: true })["image2_2M"]}
            positionCenter
          />
          <FeatureLineMobile
            id="third-line-mobile"
            _title={t("video", { returnObjects: true })["title3"]}
            text1={t("video", { returnObjects: true })["text3_1"]}
            image={t("feature", { returnObjects: true })["image3_1M"]}
            positionTopBottom
          />
          <CarouselMobile
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            id="carousel-turboram-mobile-2"
            carouselContent={t("carousel2", { returnObjects: true })}
          />
          <FeatureLineMobile id="fourth-line-mobile" _title={t("feature2", { returnObjects: true })["title"]} positionCenter />
          {/* <FeatureLineMobile
            id="fifth-line-mobile"
            _title={t("feature", { returnObjects: true })["text4"]}
            image={t("feature", { returnObjects: true })["image3_2M"]}
            absoluteCenter
            opacity="0.4"
          /> */}
          <ControlPanelMobile
            id="control-panel-mobile-turboram"
            carouselContent={t("panel", { returnObjects: true })["panels"]}
            _title={{
              title1: t("panel", { returnObjects: true })["title1"],
              title2: t("panel", { returnObjects: true })["title2"],
            }}
          />
        </>
      )}

      {config && <Configurador id="turboram-initial" content={t("configurador", { returnObjects: true })} />}

      {/* <SpecsTable
        id="specs-table"
        specsTable={t('specs', { returnObjects: true })['content']}
        specsLength={t('specs', { returnObjects: true })['content'].length}
        location={location}
        btnString="Contacte-nos"
        svgWidth="120px"
      /> */}
      {!(activeLanguage === "br") && (
        <FormContact id="form-contact" info={t("form", { returnObjects: true })} lang={activeLanguage} location={location} />
      )}
    </Layout>
  )
}

export default IndexPage

export const QueryTurboramPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["home", "turboram", "form", "footer", "header"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
