import React, { useEffect, useRef } from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'
import { font, color } from '../../layout/global'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const TemperatureCounter = ({ minTemp, maxTemp, minutes, info, _title }) => {
  const triggerRef = useRef(null),
        containerRef = useRef(null),
        h1Ref = useRef(null),
        h2Ref = useRef(null),
        minutesRef = useRef(null)

  var num = { var: 0 }

  useEffect(() => {
    gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: triggerRef.current,
        toggleActions: 'play none none none',
        start: 'center center'
      }
    })
    .fromTo([h1Ref.current, h2Ref.current], {opacity: 0, y:'15'}, {opacity: 1, y:'0', duration: 0.3, stagger: 0.6})
    .to(num, { var: minutes, duration: 2, onUpdate: () => {minutesRef.current && (minutesRef.current.innerHTML = (num.var).toFixed())}, ease: 'power4.in', }, '>-0.3')

  }, [minutes, num])
  
  return (
  <Wrapper ref={triggerRef}>
    <div ref={containerRef} id="temperatureText">
      <h2 ref={h1Ref}>{_title}</h2>
      <br/>
      <h3 ref={h2Ref}>{info.text1} {minTemp}{info.text2} {maxTemp}{info.text3} <span ref={minutesRef}>0</span> {info.text4}</h3>
    </div>
  </Wrapper>
  )
}

export default TemperatureCounter

TemperatureCounter.defaultProps = {
  minTemp: '0',
  maxTemp: '300',
  minutes: 12
}
TemperatureCounter.propTypes = {
  minTemp: PropTypes.string,
  maxTemp: PropTypes.string,
  minutes: PropTypes.number
}

const Wrapper = styled.section`
  position: relative;
  background-color: black;
  width: 100%;
  height: 100vh;
  opacity: 1;
  overflow: hidden;

  #temperatureText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:100%;
    text-align:center;
  }
  #temperatureText h1 {
    ${font.robotoBold};
    font-size: 5rem;
    color: ${color.white};
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  #temperatureText h3 {
    color: ${color.white};
    ${font.robotoBold};
    font-size: 6rem;
    line-height: 1;
    letter-spacing: 1px;
  }
  #temperatureText span {
    color: ${color.red};
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
  }
`